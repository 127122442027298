<template>
  <math-agent
    :uuid="uuid"
    actionID="26"
    i18nKey="multiplication"
    :middlewareID="middlewareID"
    :agentID="agentID"
    v-model="agent"
    :variables="variables"
    ref="math-agent-component"
    @saved="onSaved"
    :agentBlockID="agentBlockID"
    @agentClosed="$emit('agentClosed')"
  />
</template>

<script>

import MathAgent from './MathAgent.vue'
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";

  import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

    components: {
      MathAgent
    },
    props: {
      middlewareID: {
        type: Number,
        required: true,
      },
      agentID: {
        type: Number,
        default: undefined,
      },
      value: {
        type: NormalizedAgent,
        default: undefined
      },
      uuid: {
        type: String,
        required: true,
      },
      width: {
        type: String,
        default: "40%"
      },
      agentBlockID: {
        type: [ Number, String ],
        default: 1,
      },
      variables: {
        type: Object,
        default: undefined
      }
    },
    computed: {
      agent: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      toSaveRegs(){
        return this.$refs['math-agent-component'].toSaveRegs
      }
    },
    methods: {
      onSaved(payload) {
        this.$emit('saved', payload)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>